<template>
  <div>
    <Head/>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle">
        <div>{{ $fanyi("库存报告") }}&nbsp;> <span>补货商品维护</span></div>
        <div @click="$router.go(-1)">取消商品维护</div>
      </div>
      <div class="orderScreenContainer">
        <div class="orderScreenLeftContainer flexAndCenter">
          <div class="flexAndCenter">
            <el-popover placement="right" trigger="hover">
              <div>123123</div>
              <div slot="reference" class="tipsIcon">?</div>
            </el-popover>
            <div class="label">下单默认使用：</div>
            <el-switch
                v-model="is_default"
                :active-value="1"
                :inactive-value="0"
                @change="updateDefault($event)"
                active-color="#13ce66">
            </el-switch>
          </div>
        </div>
        <div class="saveButton" @click="save">保存</div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO">
            <span :title="$fanyi('状态')" class="tableHeadCon">{{ $fanyi("状态") }}</span>
          </li>
          <li class="pic">
            <span :title="$fanyi('照片')" class="tableHeadCon">{{ $fanyi("照片") }}</span>
          </li>
          <li class="submitTime">
            <span :title="$fanyi('商品名称')" class="tableHeadCon">{{ $fanyi("商品名称") }}</span>
          </li>
          <li class="goodSku">
            <span :title="$fanyi('商品SKU')" class="tableHeadCon">{{ $fanyi("商品SKU") }}</span>
          </li>
          <li class="ASINOrFNSKU">
            <span :title="$fanyi('ASIN/FNSKU')" class="tableHeadCon">{{ $fanyi("ASIN/FNSKU") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('可售量')" class="tableHeadCon">{{ $fanyi("可售量") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('不可售量')" class="tableHeadCon">{{ $fanyi("不可售量") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('出入库作业中')" class="tableHeadCon">{{ $fanyi("出入库作业中") }}</span>
          </li>
          <li class="quantityAvailable" style="line-height:30px">
            <span :title="$fanyi('RAKUMART进行中')" class="tableHeadCon">{{ $fanyi("RAKUMART进行中") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('可发往亚马逊数量')" class="tableHeadCon">{{ $fanyi("可发往亚马逊数量") }}</span>
          </li>
        </ul>
        <div>
          <div v-for="(item, index) in tableData" :key="$fanyi('表格') + index"
               style="border-bottom: 1px solid #DFDFDF;">
            <ul class="obody flexAndCenter" style="border-bottom:none">
              <!--状态-->
              <li class="oNO">
                <div class="flexAndCenter">
                  <div v-for="(statusItem,statusIndex) in item.status" :key="statusIndex" class="fontSize12">
                    {{ statusItem }}
                  </div>
                </div>
              </li>
              <!--照片-->
              <li class="pic">
                <el-popover placement="right" trigger="hover">
                  <img :src="item.link"
                       style="width: 300px;height: 300px;"
                  />
                  <img slot="reference"
                       :src="item.link"
                       class="cursorPointer"
                       style="width: 50px;height: 50px;"
                  />
                </el-popover>
              </li>
              <!--商品名称-->
              <li class="submitTime">
                <div :title="item.item_name" class="u-line-3" style="width: 200px">{{ item.item_name }}</div>
              </li>
              <!--商品SKU-->
              <li class="goodSku">
                <div :title="item.sku" class="u-line-3" style="width: 100px">{{ item.sku }}</div>
              </li>
              <!--ASIN/FNSKU-->
              <li class="ASINOrFNSKU">
                <div :title="item.item_name" class="u-line-3" style="width: 140px">{{
                    item.asin
                  }}<br>/{{ item.fn_sku }}
                </div>
              </li>
              <!--可售量-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--不可售量-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--出入库作业中-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--RAKUMART进行中-->
              <li class="quantityAvailable">
                <div></div>
              </li>
              <!--可发往亚马逊数量-->
              <li class="quantityAvailable">
                <div>

                </div>
              </li>
            </ul>
            <div>
              <div class="ordersListHeader">
                <div class="ordersLink">{{ $fanyi("链接") }}</div>
                <div class="goodsInfo">{{ $fanyi("商品信息") }}</div>
                <div class="goodsAttribute">{{ $fanyi("商品属性") }}</div>
                <div class="goodsPrice">{{ $fanyi("进货价") }}</div>
                <div class="goodsNum">{{ $fanyi("数量") }}</div>
                <div class="goodsAttribute">{{ $fanyi("OPTION") }}</div>
                <div class="goodsAttribute">{{ $fanyi("备注") }}</div>
                <div class="goodsOperation">{{ $fanyi("操作") }}</div>
              </div>
              <div class="ordersListFooterList">
                <div v-for="(orderItem, orderIndex) in item.amazon_replenishment" :key="orderIndex"
                     :style="orderIndex!=item.amazon_replenishment.length-1?'margin-bottom:8px;':''"
                     class="ordersListFooterItem">
                  <div class="ordersListFooter" style="background: #FFF0DE">
                    <!--链接-->
                    <div class="ordersLink">
                      <div class="ordersLinkInfo">
                        <div class="flexAndCenter" style="margin-bottom: 17px;">
                          <div class="ordersLinkTitle">
                            {{ orderItem.product_set.length > 0 ? $fanyi("组套商品") : $fanyi("主商品") }}
                          </div>
                        </div>
                        <div class="linkContainer">
                          <div class="redEditIconContainer">
                            <img alt="" src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <div class="baiEditIconContainer">
                            <img alt="" src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <el-input
                              v-model="orderItem.goods_link"
                              resize="none"
                              type="textarea"
                              @blur="setLink(orderItem)">
                          </el-input>
                        </div>
                      </div>
                    </div>
                    <!--商品信息-->
                    <div class="goodsInfo">
                      <div class="goodsInfo">
                        <div class="goodsName">
                          <div class="redEditIconContainer">
                            <img alt="" src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <div class="baiEditIconContainer">
                            <img alt="" src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <el-input
                              v-model="orderItem.goods_title"
                              resize="none"
                              type="textarea">
                          </el-input>
                        </div>
                        <div class="goodsImage">
                          <div class="redEditIconContainer">
                            <img alt="" src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <div class="baiEditIconContainer">
                            <img alt="" src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                          </div>
                          <el-upload
                              v-if="$route.query"
                              slot="reference"
                              :action="uploadUrl"
                              :before-upload="beforeAvatarUpload"
                              :on-success="handleAvatarSuccess"
                              :show-file-list="false"
                              accept=".jpg,.jpeg,.png"
                              class="upload-demo"
                              name="file"
                          >
                            <el-popover placement="bottom" trigger="hover">
                              <el-image :src="orderItem.image"
                                        class="cursorPointer"
                                        style="width: 300px; height: 300px"
                                        @click="$fun.toCommodityDetails(orderItem.goods_id,orderItem.from_platform)"
                              />
                              <el-image slot="reference"
                                        :src="orderItem.image"
                                        style="width: 48px;height: 48px"
                                        @click="getItemMessage(index,orderIndex,undefined)"
                              />
                            </el-popover>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                    <!--商品属性-->
                    <div class="goodsAttribute">
                      <div class="goodsAttributeDetails"
                           @click="goodsAttributeMessage(index,orderIndex,undefined,orderItem)">
                        <div class="redEditIconContainer">
                          <img alt="" src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px">
                        </div>
                        <div class="baiEditIconContainer">
                          <img alt="" src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                        </div>
                        <el-popover placement="bottom" trigger="hover">
                          <div>
                            <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                          <div slot="reference">
                            <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex"
                                 class="u-line fontSize14 color333">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                        </el-popover>
                      </div>
                    </div>
                    <!--进货价-->
                    <div class="goodsPrice">{{ orderItem.price }}</div>
                    <!--数量-->
                    <div class="goodsNum">
                      <el-input v-model="orderItem.num"/>
                    </div>
                    <!--OPTION-->
                    <div class="goodsAttribute">
                      <div class="goodsAttributeDetailsContainer"
                           @click="getItemOption(index,orderIndex,undefined,orderItem)">
                        <el-popover placement="bottom" trigger="hover">
                          <div>
                            <div v-for="(optionItem,optionIndex) in orderItem.option" :key="optionIndex">
                              <div v-if="optionItem.num>0&&optionItem.checked===true">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}* {{
                                  optionItem.num
                                }}({{ optionItem.price }}{{ optionItem.unit }}/点)
                              </div>
                            </div>
                          </div>
                          <div slot="reference">
                            <div class="optionHiddenContainer" style="width:130px">
                              <div v-for="(optionItem,optionIndex) in orderItem.option" :key="optionIndex">
                                <div v-if="optionItem.num>0&&optionItem.checked===true"
                                     class="u-line color333 fontSize14"
                                     style="text-align: left;width:130px">
                                  {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                                    optionItem.price
                                  }}{{ optionItem.unit }}/点)
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-popover>
                      </div>
                    </div>
                    <!--备注-->
                    <div class="goodsAttribute">
                      <el-input
                          v-model="orderItem.remark"
                          :rows="4"
                          resize="none"
                          type="textarea">
                      </el-input>
                    </div>
                    <!--操作-->
                    <div class="goodsOperation">
                      <div class="btnList">
                        <div class="addBtn" @click="addBundleItem(orderItem)">{{ $fanyi("新增组套商品") }}</div>
                        <el-popover placement="right" trigger="hover">
                          <div>324432</div>
                          <div slot="reference" class="tipsBtn">?</div>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div style="background: #FFF8ED">
                    <div v-for="(productItem, productIndex) in orderItem.product_set" :key="productIndex">
                      <div class="ordersListFooter">
                        <!--链接-->
                        <div class="ordersLink">
                          <div class="ordersLinkInfo">
                            <el-popover placement="left" trigger="hover">
                              <div>{{ productItem.num }}个一套</div>
                              <div slot="reference" class="flexAndCenter cursorPointer">
                                <img alt="" src="../../../../../../assets/link.svg">
                                <div class="bundleTitle">{{ $fanyi("同捆物") }}{{ productIndex + 1 }}</div>
                              </div>
                            </el-popover>
                            <div class="linkContainer" style="margin-top: 29px">
                              <div class="redEditIconContainer">
                                <img alt="" src="../../../../../../assets/redEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <div class="baiEditIconContainer">
                                <img alt="" src="../../../../../../assets/baiEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <el-input
                                  v-model="productItem.goods_link"
                                  resize="none"
                                  type="textarea"
                                  @blur="setLink(productItem)">
                              </el-input>
                            </div>
                          </div>
                        </div>
                        <!--商品信息-->
                        <div class="goodsInfo">
                          <div class="goodsInfo">
                            <div class="goodsName">
                              <div class="redEditIconContainer">
                                <img alt="" src="../../../../../../assets/redEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <div class="baiEditIconContainer">
                                <img alt="" src="../../../../../../assets/baiEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <el-input
                                  v-model="productItem.goods_title"
                                  resize="none"
                                  type="textarea">
                              </el-input>
                            </div>
                            <div class="goodsImage">
                              <div class="redEditIconContainer">
                                <img alt="" src="../../../../../../assets/redEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <div class="baiEditIconContainer">
                                <img alt="" src="../../../../../../assets/baiEditIcon.png"
                                     style="width:12px;height:12px">
                              </div>
                              <el-upload
                                  v-if="$route.query"
                                  slot="reference"
                                  :action="uploadUrl"
                                  :before-upload="beforeAvatarUpload"
                                  :on-success="handleAvatarSuccess"
                                  :show-file-list="false"
                                  accept=".jpg,.jpeg,.png"
                                  class="upload-demo"
                                  name="file"
                              >
                                <el-popover placement="bottom" trigger="hover">
                                  <el-image :src="productItem.image"
                                            class="cursorPointer"
                                            style="width: 300px; height: 300px"
                                            @click="$fun.toCommodityDetails(productItem.goods_id,productItem.from_platform)"
                                  />
                                  <el-image slot="reference"
                                            :src="productItem.image"
                                            style="width: 48px;height: 48px"
                                            @click="getItemMessage(index,orderIndex,productIndex)"
                                  />
                                </el-popover>
                              </el-upload>
                            </div>
                          </div>
                        </div>
                        <!--商品属性-->
                        <div class="goodsAttribute">
                          <div class="goodsAttributeDetails"
                               @click="goodsAttributeMessage(index,orderIndex,productIndex,productItem)">
                            <div class="redEditIconContainer">
                              <img alt="" src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px">
                            </div>
                            <div class="baiEditIconContainer">
                              <img alt="" src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                            </div>
                            <el-popover placement="bottom" trigger="hover">
                              <div>
                                <div v-for="(detailItem, detailIndex) in productItem.detail" :key="detailIndex">
                                  {{ detailItem.key }}：{{ detailItem.value }}
                                </div>
                              </div>
                              <div slot="reference">
                                <div v-for="(detailItem, detailIndex) in productItem.detail" :key="detailIndex"
                                     class="u-line fontSize14 color333">
                                  {{ detailItem.key }}：{{ detailItem.value }}
                                </div>
                              </div>
                            </el-popover>
                          </div>
                        </div>
                        <!--进货价-->
                        <div class="goodsPrice">{{ productItem.price }}</div>
                        <!--数量-->
                        <div class="goodsNum">
                          <el-input v-model="productItem.num"/>
                        </div>
                        <!--OPTION-->
                        <div class="goodsAttribute">
                          <div class="goodsAttributeDetailsContainer"
                               @click="getItemOption(index,orderIndex,productIndex,productItem)">
                            <el-popover placement="bottom" trigger="hover">
                              <div>
                                <div v-for="(optionItem,optionIndex) in productItem.option" :key="optionIndex">
                                  <div v-if="optionItem.num>0&&optionItem.checked===true">
                                    {{ optionIndex + 1 }}.{{ optionItem.name_translate }}* {{
                                      optionItem.num
                                    }}({{ optionItem.price }}{{ optionItem.unit }}/点)
                                  </div>
                                </div>
                              </div>
                              <div slot="reference">
                                <div class="optionHiddenContainer" style="width:130px">
                                  <div v-for="(optionItem,optionIndex) in productItem.option" :key="optionIndex">
                                    <div v-if="optionItem.num>0&&optionItem.checked===true"
                                         class="fontSize14 u-line color333"
                                         style="text-align: left;width:130px">
                                      {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                                        optionItem.price
                                      }}{{ optionItem.unit }}/点)
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-popover>
                          </div>
                        </div>
                        <!--备注-->
                        <div class="goodsAttribute">
                          <el-input
                              v-model="productItem.remark"
                              :rows="4"
                              resize="none"
                              type="textarea">
                          </el-input>
                        </div>
                        <!--操作-->
                        <div class="goodsOperation">
                          <div class="fontSize14 cursorPointer colorB4272B"
                               style="text-decoration-line: underline;"
                               @click="deleteLink(index,orderIndex,productIndex)">{{ $fanyi("删除") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="skuErrorDialogVisible"
        center
        title="注意"
        width="656px">
      <div class="editGoodsContainer">
        <div>
          <div v-for="(item,index) in skuErrorList" :key="index">{{ item }}</div>
        </div>
        <div style="margin-top: 40px">以上SKU商品没设置亚马逊补货入仓商品，无法生成订单，请确认。</div>
      </div>
    </el-dialog>
    <div class="goodsAttributeEditContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="$fanyi('属性编辑')"
          :visible.sync="goodsDetailDialogVisible"
          center
          width="516px">
        <goodsAttributeEdit :key="timer" ref="goodsAttributeEditDialog" :goodsDetailList="goodsDetailList"/>
      </el-dialog>
      <editOption/>
    </div>
  </div>
</template>
<script>
import Head from "../../../../../../components/head/HomePageTop.vue";
import goodsAttributeEdit from "../components/goodsAttributeEdit.vue";
import editOption from "../components/option.vue";

export default {
  data() {
    return {
      timer: 0,
      goodsIndexInfo: {
        index: 0,
      },
      showGoodsOptionList: [],
      goodsDetailDialogVisible: false,
      showGoodsOptionDialogVisible: false,
      orderNum: 0,
      is_default: 0,
      dialogTitle: this.$fanyi("新增"),
      skuErrorList: [],
      goodsDetailList: [],
      skuErrorDialogVisible: false,
      tableData: [],
      optionList: [],
    };
  },
  components: {
    Head,
    goodsAttributeEdit,
    editOption
  },
  created() {
    this.$api.optionList({
      need_desc: true
    }).then((res) => {
      res.data.forEach((item) => {
        item.total = 0;
      })
      this.optionList = res.data;
    });
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      this.is_default = this.$fun.deepClone(this.$store.state.replenishmentGoodsInfo.orderItem.is_default)
      let obj = this.$fun.deepClone(this.$store.state.replenishmentGoodsInfo.item);
      obj.amazon_replenishment.forEach((item) => {
        if (item.option == null) {
          item.option = [];
        }
        item.product_set.forEach((productItem) => {
          if (productItem.option == null) {
            productItem.option = [];
          }
        })
      })
      this.tableData = this.$fun.deepClone([obj]);
      this.$forceUpdate();
    },
    //根据链接获取具体商品信息
    setLink(item) {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let timestamp = Math.floor(new Date().valueOf() / 1000);
      let sign = this.$fun.setToolValue(timestamp);
      this.$api.EuropeKeywordSearchProduct({
        keywords: item.goods_link,
        timestamp: timestamp,
        sign: sign
      }).then((result) => {
            this.loading.close();
            if (result.code != 0) {
              this.$message.error(result.msg);
              return
            }
            item.goods_id = result.data.result.goodsId;
            item.image = result.data.result.images[0];
            item.shop_id = result.data.result.shopId;
            item.shop_name = result.data.result.shopName;
            item.goods_title = result.data.result.titleC;
            let goodsInventoryOptionStr = '';
            let detail = [];
            result.data.result.goodsInfo.specification.forEach((specificationItem, specificationIndex) => {
              foo:for (let i = 0; i < specificationItem.valueC.length; i++) {
                if (i === 0 && specificationIndex !== result.data.result.goodsInfo.specification.length - 1) {
                  specificationItem.active = 0;
                  goodsInventoryOptionStr += specificationItem.valueC[i].name + "㊖㊎";
                  detail.push({
                    key: specificationItem.keyC,
                    value: specificationItem.valueC[i].name,
                  })
                }
                if (specificationIndex === result.data.result.goodsInfo.specification.length - 1) {
                  goodsInventoryOptionStr += `${specificationItem.valueC[i].name}`;
                  detail.push({
                    key: specificationItem.keyC,
                    value: specificationItem.valueC[i].name,
                  })
                  item.detail = detail;
                  specificationItem.active = 0;
                  for (let j = 0; j < result.data.result.goodsInfo.goodsInventory.length; j++) {
                    if (result.data.result.goodsInfo.goodsInventory[j].keyC === goodsInventoryOptionStr) {
                      result.data.result.goodsInfo.goodsInventory[j].valueC[0].name = specificationItem.valueC[i].name;
                      if (specificationItem.valueC[i].picUrl !== "") {
                        result.data.result.goodsInfo.goodsInventory[j].valueC[0].picUrl = specificationItem.valueC[i].picUrl;
                      }
                      item.sku_id = result.data.result.goodsInfo.goodsInventory[j].valueC[0].skuId;
                      item.spec_id = result.data.result.goodsInfo.goodsInventory[j].valueC[0].specId;
                      item.price = result.data.result.goodsInfo.goodsInventory[j].valueC[0].price;
                      break foo;
                    }
                  }
                  goodsInventoryOptionStr = goodsInventoryOptionStr.substr(0, goodsInventoryOptionStr.length - item.valueC[i].name.length);
                }
              }
            });
            item.goodsInfo = result.data.result.goodsInfo;
            item.fromPlatform = result.data.result.fromPlatform;
          }
      )
    },
    //获取商品规格信息
    goodsAttributeMessage(index, orderIndex, productIndex, productItem) {
      this.goodsIndexInfo = {
        index: index,
        orderIndex: orderIndex,
        productIndex: productIndex
      }
      if (productItem.goods_id != '' && productItem.fromPlatform != '') {
        if (productItem.goodsInfo == undefined) {
          this.$api.getGoodsAttribute({
            shop_type: productItem.from_platform,
            goods_id: productItem.goods_id
          }).then((res) => {
            if (res.data.length > 0) {
              productItem.goodsInfo = {
                goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
                specification: this.$fun.deepClone(res.data[0].specification),
              }
              res.data[0].specification.forEach((specificationItem) => {
                foo:for (let j = 0; j < productItem.detail.length; j++) {
                  for (let i = 0; i < specificationItem.valueC.length; i++) {
                    if (specificationItem.keyC === productItem.detail[j].key && specificationItem.valueC[i].name === productItem.detail[j].value) {
                      specificationItem.active = i;
                      specificationItem.oldActive = this.$fun.deepClone(i);
                      break foo;
                    } else {
                      specificationItem.active = 0;
                      specificationItem.oldActive = this.$fun.deepClone(0);
                    }
                  }
                }
              })
            } else {
              productItem.goodsInfo = {
                goodsInventory: [],
                specification: [],
              }
            }
            this.timer = new Date().getTime();
            this.goodsInfo = productItem;
            this.goodsDetailList = res.data[0].specification;
            this.goodsDetailDialogVisible = true;
          })
        } else {
          if (productItem.goodsInfo.specification.length > 0) {
            productItem.goodsInfo.specification.forEach((specificationItem) => {
              foo:for (let j = 0; j < productItem.detail.length; j++) {
                for (let i = 0; i < specificationItem.valueC.length; i++) {
                  if (specificationItem.keyC === productItem.detail[j].key && specificationItem.valueC[i].name === productItem.detail[j].value) {
                    specificationItem.active = i;
                    specificationItem.oldActive = this.$fun.deepClone(i);
                    break foo;
                  } else {
                    specificationItem.active = 0;
                    specificationItem.oldActive = this.$fun.deepClone(0);
                  }
                }
              }
            })
          }
          this.timer = new Date().getTime();
          this.goodsInfo = productItem;
          this.goodsDetailList = productItem.goodsInfo.specification;
          this.goodsDetailDialogVisible = true;
        }
      } else {
        this.$message.error(this.$fanyi('请先获取商品信息'))
      }
    },
    //获取商品索引
    getItemMessage(index, orderIndex, productIndex) {
      this.goodsIndexInfo = {
        index: index,
        orderIndex: orderIndex,
        productIndex: productIndex
      }
    },
    //获取option详细
    getItemOption(index, orderIndex, productIndex, productItem) {
      this.goodsIndexInfo = {
        index: index,
        orderIndex: orderIndex,
        productIndex: productIndex
      }
      this.orderNum = productItem.num;
      let optionList = this.$fun.deepClone(this.optionList);
      if (productItem.option.length > 0) {
        productItem.option.forEach((item1) => {
          optionList.forEach((optionItem) => {
            if (item1.name == optionItem.name) {
              optionItem.num = item1.num;
              optionItem.checked = item1.checked;
            }
          })
        })
      }
      this.showGoodsOptionList = this.$fun.deepClone(optionList);
      this.showGoodsOptionDialogVisible = true
    },
    //图片上传成功
    handleAvatarSuccess(res) {
      if (this.goodsIndexInfo.productIndex == undefined) {
        this.tableData[this.goodsIndexInfo.index].amazon_replenishment[this.goodsIndexInfo.orderIndex].image = res.data;
      } else {
        this.tableData[this.goodsIndexInfo.index].amazon_replenishment[this.goodsIndexInfo.orderIndex].product_set[this.goodsIndexInfo.productIndex].image = res.data;
      }
      this.loading.close();
    },
    //图片上传
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    //下单默认使用状态切换
    updateDefault(e) {
      if (this.tableData[0].amazon_replenishment.length == 0 || e == 0) {
        this.is_default == 0 ? this.is_default = 1 : this.is_default = 0
        this.$message.error(this.$fanyi('必须要有一个默认补货下单的商品链接'));
      } else {
        this.$api.replenishmentStatus({
          inventory_id: this.$store.state.replenishmentGoodsInfo.orderItem.inventory_id,
          id: this.$store.state.replenishmentGoodsInfo.orderItem.id,
          is_default: e
        }).then((res) => {
          this.is_default == 0 ? this.is_default = 1 : this.is_default = 0
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg);
        })
      }
    },
    //保存补货商品维护
    save() {
      let setGoodsArr = [];
      let replenishmentLinkArr = [];
      this.tableData.forEach((item, index) => {
        item.amazon_replenishment.forEach((orderItem, orderIndex) => {
          replenishmentLinkArr.push(orderItem)
          orderItem.product_set.forEach((productItem, productIndex) => {
            setGoodsArr.push({
              set_from_id: productItem.set_from_id == undefined ? orderItem.id : productItem.set_from_id,
              id: productItem.id,
              goods_link: productItem.goods_link,
              image: productItem.image,
              goods_id: productItem.goods_id,
              detail: JSON.stringify(productItem.detail),
              sku_id: productItem.sku_id,
              price: productItem.price,
              warehousing: 1,
              remark: productItem.remark,
              shop_id: productItem.shop_id,
              num: productItem.num,
              spec_id: productItem.spec_id,
              shop_name: productItem.shop_name,
              goods_title: productItem.goods_title,
              option: JSON.stringify(productItem.option)
            })
            if (index == this.tableData.length - 1 && orderIndex == item.amazon_replenishment.length - 1 && productIndex == orderItem.product_set.length - 1) {
              this.saveReplenishmentLinkGoods(replenishmentLinkArr)
              this.$api.amazonSupplySetModify({list: setGoodsArr}).then((res) => {
                if (res.code != 0) return this.$message.error(res.msg);
                this.$message.success(res.msg);
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1000)
              })
            }
          })
        })
      })
    },
    //增加同捆商品
    addBundleItem(item) {
      item.product_set.push({
        goods_link: '',
        image: this.$fun.deepClone(item.image),
        goods_id: '',
        detail: [],
        sku_id: '',
        spec_id: '',
        price: '',
        num: 1,
        warehousing: 1,
        shop_id: '',
        shop_name: '',
        goods_title: '',
        remark: '',
        option: this.$fun.deepClone(item.option),
        isAdd: true
      })
    },
    //保存补货商品
    saveReplenishmentLinkGoods(replenishmentLinkArr) {
      replenishmentLinkArr.forEach((item) => {
        this.$api.replenishmentLinkSave({
          inventory_id: item.inventory_id,
          id: item.id,
          goods_link: item.goods_link,
          image: item.image,
          goods_id: item.goods_id,
          detail: JSON.stringify(item.detail),
          sku_id: item.sku_id,
          price: item.price,
          num: item.num,
          warehousing: 1,
          remark: item.remark,
          shop_id: item.shop_id,
          shop_name: item.shop_name,
          goods_title: item.goods_title,
          spec_id: item.spec_id,
          option: JSON.stringify(item.option)
        }).then((res) => {

        })
      })
    },
    //删除同捆商品
    deleteLink(index, orderIndex, productIndex) {
      if (this.tableData[index].amazon_replenishment[orderIndex].product_set[productIndex].isAdd != undefined) {
        this.tableData[index].amazon_replenishment[orderIndex].product_set.splice(productIndex, 1);
      } else {
        this.$confirm(
            '是否删除同捆商品',
            '注意',
            {
              confirmButtonText: this.$fanyi('确定'), //确定
              cancelButtonText: this.$fanyi('取消'), //取消
              type: "warning",
              center: true,
              dangerouslyUseHTMLString: true,
            }
        ).then(() => {
          this.$api.amazonSupplySetDelete({
            id: this.tableData[index].amazon_replenishment[orderIndex].product_set[productIndex].id,
          }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi(res.msg));
            this.tableData[index].amazon_replenishment[orderIndex].product_set.splice(productIndex, 1);
          });
        })
      }
    }
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/replenishmentGoodsMaintenance.scss";
</style>

<template>
  <div>
    <div class="goodsAttributeEditTypeContainer">
      <!--    goodsDetailList #c35255-->
      <el-button :style="attributeTypeStatus===1?'background:#c35255;color:#fff':'background:#fff'" size="mini"
                 @click="attributeTypeStatus=1">
        {{ $fanyi('网站属性选择') }}
      </el-button>
      <el-button :style="attributeTypeStatus===2?'background:#c35255;color:#fff':'background:#fff'" size="mini"
                 @click="attributeTypeStatus=2">
        {{ $fanyi('人工编辑属性') }}
      </el-button>
    </div>
    <div v-show="attributeTypeStatus===1" class="AttributeListContainer">
      <div v-for="(item,index) in goodsDetailList" :key="index" class="goodsDetailContainer"
           style="display: flex">
        <div class="keyContainer">{{ item.keyT }}：</div>
        <div class="valueAttributeListContainer">
          <div v-for="(items,valueIndex) in item.valueC" :key="valueIndex"
               :class="item.active===valueIndex?'fontSize14 color333 active':'fontSize14 color333'"
               @click="item.active=valueIndex;$forceUpdate();">{{ items.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="attributeTypeStatus===2" class="artificialAttributeListContainer">
      <div class="artificialAttributeTitle flexAndCenter">
        <div>{{ $fanyi('属性名称') }}</div>
        <div>{{ $fanyi('具体属性值') }}</div>
      </div>
      <div>
        <div v-for="(item,index) in artificialAttributeEditList" :key="index"
             class="artificialAttributeEditContainer flexAndCenter">
          <div class="artificialAttributeEditLeftContainer flexAndCenter">
            <el-input v-model="item.key" size="small" style="width: 200px;margin-right: 15px"></el-input>
            <el-input v-model="item.value" size="small" style="width: 200px"></el-input>
          </div>
          <div class="artificialAttributeEditRightContainer" style="margin-left: 10px;cursor: pointer"
               @click="artificialAttributeEditList.splice(index,1)">
            <img alt="" src="../../../../../../assets/7.png">
          </div>
        </div>
        <div class="addAttributeText fontSize14 flexAndCenter"
             style="cursor: pointer"
             @click="artificialAttributeEditList.push({key:'',value:''})">
          <img alt="" src="../../../../../../assets/5.png" style="width:16px;margin-right:7px">
          {{ $fanyi("添加属性") }}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" style="margin-top: 30px">
       <el-button @click="$parent.goodsDetailDialogVisible=false">{{ $fanyi('取 消') }}</el-button>
       <el-button type="primary" @click="saveGoodsDetail">{{ $fanyi('保 存') }}</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "goodsAttributeEdit",
  props: {
    goodsDetailList: {
      type: Array
    },
    timer: {
      type: String
    }
  },
  data() {
    return {
      attributeTypeStatus: 1,
      artificialAttributeEditList: [{
        key: '',
        value: ''
      }, {
        key: '',
        value: ''
      }]
    }
  },
  methods: {
    //保存商品规格
    saveGoodsDetail() {
      let arr = [];
      this.artificialAttributeEditList.forEach((item, index) => {
        if (item.key === '' || item.value === '') {
          this.artificialAttributeEditList.splice(index, 1)
        }
      })
      this.attributeTypeStatus === 1 ? arr = this.goodsDetailList : arr = this.artificialAttributeEditList
      let newDetailList = [];
      let pic = '';
      let status = false;
      let sku_id = '';
      let str = '';
      let spec_id = '';
      if (this.attributeTypeStatus === 1) {
        this.goodsDetailList.forEach((item) => {
          if (item.valueC[item.active].picUrl !== '') {
            pic = item.valueC[item.active].picUrl
          }
          newDetailList.push({
            key: item.keyC,
            value: item.valueC[item.active].name
          })
          str += item.valueC[item.active].name + '㊖㊎'
        })
        str = str.substr(0, str.length - 2);
        if (this.$parent.$parent.goodsInfo.goodsInfo.goodsInventory > 0) {
          this.$parent.$parent.goodsInfo.goodsInfo.goodsInventory.forEach((item) => {
            if (str === item.keyC && (this.$parent.$parent.goodsInfo.num > item.startQuantity || this.$parent.$parent.goodsInfo.num === item.startQuantity)) {
              this.goodsInfo.price = item.price;
              spec_id = item.spec_id;
              sku_id = item.sku_id;
            }
          })
        }
      } else {
        var sarr = arr.sort();
        for (let i = 0; i < sarr.length; i++) {
          if (i !== sarr.length - 1) {
            if (sarr[i].key == sarr[i + 1].key) {
              status = true;
              break;
            }
          }
        }
      }
      if (status === true) {
        this.$message.error('チェック便宜上、属性制限しております。必要な場合では担当者までご連絡くださいませ。');
        return
      }
      if (this.$parent.$parent.goodsIndexInfo.productIndex != undefined) {
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].product_set[this.$parent.$parent.goodsIndexInfo.productIndex].spec_id = spec_id;
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].product_set[this.$parent.$parent.goodsIndexInfo.productIndex].sku_id = sku_id;
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].product_set[this.$parent.$parent.goodsIndexInfo.productIndex].detail = this.attributeTypeStatus === 1 ? newDetailList : arr;
        if (pic !== '') {
          this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].product_set[this.$parent.$parent.goodsIndexInfo.productIndex].image = pic;
        }
      } else {
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].spec_id = spec_id;
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].sku_id = sku_id;
        this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].detail = this.attributeTypeStatus === 1 ? newDetailList : arr;
        if (pic !== '') {
          this.$parent.$parent.tableData[this.$parent.$parent.goodsIndexInfo.index].amazon_replenishment[this.$parent.$parent.goodsIndexInfo.orderIndex].image = pic;
        }
      }
      this.$parent.$parent.goodsDetailDialogVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../../../userOrder/views/css/orderDetails.scss";

.goodsDetailContainer {
  .keyContainer {
    margin-top: 20px;
    min-width: 80px;
  }

  .valueAttributeListContainer {
    display: flex;
    flex-wrap: wrap;

    div {
      padding: 8px 16px;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid #DFDFDF;
      margin: 20px 0 0 20px;
      cursor: pointer;
    }

    .active {
      border: 1px solid #B4272B;
      color: #B4272B;
    }
  }
}

.artificialAttributeTitle {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #F0F0F0;
  margin-top: 20px;
  margin-right: 26px;

  div {
    width: 215px;
    height: 30px;
    padding-left: 8px;
    line-height: 30px;
  }
}

.artificialAttributeEditContainer {
  border-bottom: 1px solid #DFDFDF;

  .artificialAttributeEditLeftContainer {
    padding: 10px 8px;
  }
}

.addAttributeText {
  margin-top: 12px;
  text-decoration: underline;
  color: #50C136;
  cursor: pointer;
}

.goodsAttributeEditTypeContainer {
  margin-top: 36px;

  .el-button + .el-button {
    margin-left: 26px;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 120px;
    height: 34px;
    border-radius: 8px;
    padding: 0;
    font-size: 14px;
  }

  button:first-child {
    border: 1px solid #B4272B;
    color: #B4272B;
  }
}

.AttributeListContainer {
  height: 600px;
  overflow: scroll;
}
</style>
